var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-container",
    [
      _c("head-layout", {
        attrs: {
          "head-btn-options": _vm.headBtnOptions,
          "head-title": _vm.$t(
            "cip.plat.sys.dataStandard.title.indexHeadTitle"
          ),
        },
        on: { "head-add": _vm.headAdd, "head-delete": _vm.handDelete },
      }),
      _c("grid-head-layout", {
        ref: "gridHeadLayout",
        attrs: { "search-columns": _vm.searchColumns },
        on: {
          "grid-head-search": _vm.searchChange,
          "grid-head-empty": _vm.searchReset,
        },
        model: {
          value: _vm.searchForm,
          callback: function ($$v) {
            _vm.searchForm = $$v
          },
          expression: "searchForm",
        },
      }),
      _c("grid-layout", {
        ref: "gridLayOut",
        attrs: {
          "grid-row-btn": _vm.gridRowBtn,
          "table-options": _vm.tableOptions,
          "table-data": _vm.tableData,
          "table-loading": _vm.tableLoading,
          "data-total": _vm.page.total,
          page: _vm.page,
        },
        on: {
          "page-current-change": _vm.onLoad,
          "page-size-change": _vm.onLoad,
          "page-refresh-change": _vm.onLoad,
          "gird-handle-select-click": _vm.selectionChange,
          "grid-row-detail-click": _vm.rowView,
          "row-remove": _vm.rowRemove,
          "row-edit": _vm.rowEdit,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }